import Footer from "components/Footer";

const AppLayout = ({ children }) => {
  return (
    <>
      <div className="App">{children}</div>
      <Footer />
    </>
  );
};

export default AppLayout;
