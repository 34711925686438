import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import matter from "gray-matter";
import remarkGfm from "remark-gfm";
import { Buffer } from "buffer"; // Import Buffer polyfill

// Ensure Buffer is globally available
window.Buffer = window.Buffer || Buffer;

const BlogPost = () => {
  const { slug } = useParams(); // Get post slug from URL
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`/posts/${slug}.md`);

        if (!response.ok) {
          throw new Error(`Failed to load post: ${response.statusText}`);
        }

        const text = await response.text();
        const { data, content } = matter(text); // Extract metadata
        setPost({ ...data, content });
      } catch (err) {
        console.error("Error fetching post:", err);
        setError(err.message);
      }
    };

    fetchPost();
  }, [slug]);

  if (error) return <p style={{ color: "red" }}>Error: {error}</p>;
  if (!post) return <p>Loading...</p>;

  return (
    <div style={{ maxWidth: "800px", margin: "0 auto", padding: "20px" }}>
      <h1>{post.title}</h1>
      <p><strong>Date:</strong> {post.date}</p>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{post.content}</ReactMarkdown>
    </div>
  );
};

export default BlogPost;
