import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Row, Col, Image, Spin } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Separator } from "components/common";

// Fetch gallery metadata from its folder
const fetchGallery = async (slug) => {
  try {
    const response = await fetch(`/galleries/${slug}/index.json`);
    if (!response.ok) throw new Error("Failed to load gallery");
    return await response.json();
  } catch (err) {
    console.error(err);
    return null;
  }
};

const GalleryView = () => {
  const { slug } = useParams();
  const [gallery, setGallery] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadGallery = async () => {
      const data = await fetchGallery(slug);
      setGallery(data);
      setLoading(false);
    };
    loadGallery();
  }, [slug]);

  if (loading)
    return (
      <Spin
        size="large"
        style={{ display: "block", margin: "auto", marginTop: "20vh" }}
      />
    );
  if (!gallery)
    return (
      <p style={{ textAlign: "center", color: "red" }}>Gallery not found</p>
    );

  return (
    <div className="slide dotted">
      <div className="container centered">
        <h2 className="glitch-text">{gallery.title}</h2>
        <p>{gallery.description}</p>
        <Separator height={2} />

        <Image.PreviewGroup>
          <Row gutter={[16, 16]} justify="center">
            {gallery.images.map((img, index) => (
              <Col xs={24} sm={12} md={8} lg={6} key={index}>
                <div
                  style={{
                    height: "100%",
                    minHeight: "280px",
                    overflow: "hidden",
                    borderRadius: "10px",
                  }}
                >
                  <Image
                    src={`/galleries/${slug}/${img}`}
                    alt={`Gallery Image ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Image.PreviewGroup>

        <Separator height={2} />
        <div style={{ textAlign: "left" }}>
          <Button>
            <Link to="/gallery">
              <LeftOutlined /> Back to Galleries
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GalleryView;
