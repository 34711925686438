import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Tag } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Separator } from "components/common";

const { Meta } = Card;
const gutter = [
  { xs: 8, sm: 16, md: 32 },
  { xs: 8, sm: 16, md: 32 },
];

// Fetch blog posts dynamically
const fetchPosts = async () => {
  try {
    const response = await fetch("/posts/index.json"); // Blog metadata file
    if (!response.ok) throw new Error("Failed to load blog posts");
    return await response.json();
  } catch (err) {
    console.error(err);
    return [];
  }
};

// Blog Card Component
const BlogCard = ({ title, date, excerpt, slug, tags }) => {
  return (
    <Col md={12} lg={8} style={{ width: "100%" }}>
      <Card
        hoverable
        actions={[
          <Link to={`/blog/${slug}`}>
            <LinkOutlined /> READ MORE
          </Link>,
        ]}
      >
        <Meta title={title} description={excerpt} />
        <p style={{ fontSize: "12px", color: "#888", marginTop: "10px" }}>
          <strong>Date:</strong> {date}
        </p>
        <div style={{ marginTop: "10px" }}>
          {tags.map((tag) => (
            <Tag key={tag} color="geekblue">
              {tag}
            </Tag>
          ))}
        </div>
      </Card>
    </Col>
  );
};

// Blog Section with Categories
const BlogSection = ({ category, posts }) => (
  <>
    <h3 className="category-title">{category}</h3>
    <Row gutter={gutter} justify="center" align="middle">
      {posts.map((post) => (
        <BlogCard
          key={post.slug}
          title={post.title}
          date={post.date}
          excerpt={post.excerpt}
          slug={post.slug}
          tags={post.tags}
        />
      ))}
    </Row>
    <Separator height={2} />
  </>
);

// Main Blog Page
const BlogPage = () => {
  const [postsByCategory, setPostsByCategory] = useState({});

  useEffect(() => {
    const loadPosts = async () => {
      const blogPosts = await fetchPosts();

      // Group posts by category
      const groupedPosts = blogPosts.reduce((acc, post) => {
        acc[post.category] = acc[post.category] || [];
        acc[post.category].push(post);
        return acc;
      }, {});

      setPostsByCategory(groupedPosts);
    };

    loadPosts();
  }, []);

  return (
    <>
      <div className="slide dotted">
        <div className="container centered">
          <h2 className="glitch-text">Blog</h2>
          <Separator height={2} />

          {Object.entries(postsByCategory).map(([category, posts]) => (
            <BlogSection key={category} category={category} posts={posts} />
          ))}

          <div style={{ textAlign: "right" }}>
            <Button>
              <Link to="/">Back home</Link>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
