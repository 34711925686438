import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ConfigProvider, theme } from "antd";

import AppLayout from "layouts/AppLayout";

// main pages
import Home from "pages/Home";
// import Projects from "pages/Projects";

import BlogPage from "pages/Blog";
import BlogPost from "pages/BlogPost";
import GalleryPage from "pages/Gallery";
import GalleryView from "pages/GalleryView";

// error pages
import NotFoundPage from "pages/NotFoundPage";

import reportWebVitals from "./reportWebVitals";

// style
import "index.css";

// Wrapper for layout-based routes
const AppRoute = ({ element: Component, layout: Layout }) => {
  return (
    <Layout>
      {Component}
    </Layout>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      algorithm: theme.darkAlgorithm, // Dark mode
      token: {
        // 🎨 Custom Theme Variables
        colorPrimary: "#e86900",
        colorLink: "#e86900",
        colorBgBase: "#101010",
        colorBgContainer: "#1f1f1f",
        colorPopoverBg: "#080808",
        borderRadius: 15,
        colorBorder: "#6f6c6c",
        colorBorderSecondary: "#424242",
        fontSize: 14,
        colorTextBase: "#ffffff",
      },
      components: {
        Button: {
          colorPrimary: "#e86900", // Buttons use orange
        },
        Link: {
          colorPrimary: "#e86900", // Links use orange
        },
        Icon: {
          colorPrimary: "#e86900", // Ant Design Icons use orange
        },
        Card: {
          borderRadius: 15,
        },
        Layout: {
          headerBg: "#101010",
          footerBg: "#101010",
        },
      },
    }}
  >
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppRoute layout={AppLayout} element={<Home />} />} />
        {/* <Route path="/projects" element={<AppRoute layout={AppLayout} element={<Projects />} />} /> */}

        <Route path="/blog" element={<AppRoute layout={AppLayout} element={<BlogPage />} />} />
        <Route path="/blog/:slug" element={<AppRoute layout={AppLayout} element={<BlogPost />} />} />
        
        <Route path="/gallery" element={<AppRoute layout={AppLayout} element={<GalleryPage />} />} />
        <Route path="/gallery/:slug" element={<AppRoute layout={AppLayout} element={<GalleryView />} />} />


        <Route path="/404" element={<AppRoute layout={AppLayout} element={<NotFoundPage />} />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </BrowserRouter>
  </ConfigProvider>
);

// Performance Monitoring
reportWebVitals();
