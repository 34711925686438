import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Tag } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Separator } from "components/common";

const { Meta } = Card;
const gutter = [
  { xs: 8, sm: 16, md: 32 },
  { xs: 8, sm: 16, md: 32 },
];

// Fetch galleries dynamically
const fetchGalleries = async () => {
  try {
    const response = await fetch("/galleries/index.json"); // Metadata file for galleries
    if (!response.ok) throw new Error("Failed to load galleries");
    return await response.json();
  } catch (err) {
    console.error(err);
    return [];
  }
};

// Gallery Card Component
const GalleryCard = ({ title, description, cover, slug, tags }) => {
  return (
    <Col md={12} lg={8} style={{ width: "100%" }}>
      <Card
        hoverable
        cover={<img src={cover} alt={title} style={{ width: "100%", height: "200px", objectFit: "cover" }} />}
        actions={[
          <Link to={`/gallery/${slug}`}>
            <LinkOutlined /> VIEW GALLERY
          </Link>,
        ]}
      >
        <Meta title={title} description={description} />
        <div style={{ marginTop: "10px" }}>
          {tags.map((tag) => (
            <Tag key={tag} color="volcano">
              {tag}
            </Tag>
          ))}
        </div>
      </Card>
    </Col>
  );
};

// Main Gallery Page
const GalleryPage = () => {
  const [galleries, setGalleries] = useState([]);

  useEffect(() => {
    const loadGalleries = async () => {
      const galleryData = await fetchGalleries();
      setGalleries(galleryData);
    };
    loadGalleries();
  }, []);

  return (
    <>
      <div className="slide dotted">
        <div className="container centered">
          <h2 className="glitch-text">Gallery</h2>
          <Separator height={2} />

          <Row gutter={gutter} justify="center" align="middle">
            {galleries.map((gallery) => (
              <GalleryCard
                key={gallery.slug}
                title={gallery.title}
                description={gallery.description}
                cover={gallery.cover}
                slug={gallery.slug}
                tags={gallery.tags}
              />
            ))}
          </Row>

          <Separator height={2} />
          <div style={{ textAlign: "right" }}>
            <Button>
              <Link to="/">Back home</Link>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryPage;
